import React, { useEffect, useState } from "react";
import { without, filter } from "lodash";
import styled from "styled-components";
import nurseService from "../../utils/api/v1/nurseService";

// typings

// components
import Flex from "../Containers/Flex";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import Checkbox from "@material-ui/core/Checkbox";
import { SectionHeader } from "../Containers/SpacedPaper";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Bold from "../Typography/Bold";
import SButton from "../Buttons/SButton";
import Swal from "sweetalert2";

const LabName = styled.span`
  font-size: 1rem;
  font-weight: 700;
  min-width: 20rem;
`;
interface ServiceItem {
  displayName: string;
  id: string;
  labName: string;
}
interface LabServiceSelectProps {
  nurseId: string;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setError: (error: any) => void;
  labServicesIds: string[];
  nurseLabServiceIds: string[];
  setNurseLabServiceIds: (nurseLabServiceIds: string[]) => void;
  defaultNurseLabServiceIds: string[];
  setDefaultNurseLabServiceIds: (defaultNurseLabServiceIds: string[]) => void;
  labServices: ServiceItem[];
  label: string;
  formHelperText: string;
}

const LabServiceSelect = (props: LabServiceSelectProps): JSX.Element => {
  const [servicesHash, setServicesHash] = useState<object>({});

  useEffect(() => {
    setServicesHash(
      props.labServices.reduce((acc, labService) => {
        acc[labService.id] = labService.displayName;
        return acc;
      }, {})
    );
  }, [props.labServices]);

  const labNames = [
    ...new Set(props.labServices.map((service) => service.labName)),
  ].sort();

  const addAllServices = (labName: string) => {
    const filteredLabServices = props.labServices.filter(
      (labService) =>
        labService.labName === labName &&
        !props.nurseLabServiceIds.includes(labService.id)
    );

    props.setNurseLabServiceIds(
      props.nurseLabServiceIds.concat(
        filteredLabServices.map((labService) => labService.id)
      )
    );
  };

  const removeAllServices = (labName: string) => {
    const filteredLabServicesIds = props.labServices
      .filter((labService) => labService.labName === labName)
      .map((labService) => labService.id);
    props.setNurseLabServiceIds(
      without(props.nurseLabServiceIds, ...filteredLabServicesIds)
    );
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    props.setNurseLabServiceIds(event.target.value as string[]);
  };

  const handleDelete = (event: React.MouseEvent, value: string): void => {
    event.preventDefault();
    props.setNurseLabServiceIds(without(props.nurseLabServiceIds, value));
  };

  const handleSubmit = async (): Promise<void> => {
    props.setLoading(true);
    try {
      const newLabServiceIds = filter(
        props.nurseLabServiceIds,
        (id: string) => {
          return props.defaultNurseLabServiceIds.indexOf(id) === -1;
        }
      );
      const deletedLabServiceIds = filter(
        props.defaultNurseLabServiceIds,
        (id: string) => {
          return props.nurseLabServiceIds.indexOf(id) === -1;
        }
      );

      const data = {
        new_lab_services: newLabServiceIds.map((id: string) => {
          return { value: id };
        }),
        deleted_lab_services: deletedLabServiceIds.map((id: string) => {
          return { value: id };
        }),
      };
      await nurseService.patchNurse(props.nurseId, data);
      props.setDefaultNurseLabServiceIds(props.nurseLabServiceIds);
      Swal.fire({
        title: "¡Servicios actualizados!",
        icon: "success",
        text: "Los servicios del/la tomador/a se han actualizado correctamente.",
      });
    } catch (error) {
      props.setError(error);
      console.log(error);
    }
    props.setLoading(false);
  };

  return (
    <Flex direction="column" padding="0 2rem 2rem 2rem">
      <SectionHeader>
        <Bold>{props.label}</Bold>
      </SectionHeader>
      <hr />
      <Flex direction="column" padding="0 2rem 2rem 2rem">
        {labNames.map((labName: string, index: number) => (
          <Flex
            key={index}
            align="center"
            direction="row"
            padding="0 2rem 1rem 2rem"
            wrap="wrap"
          >
            <LabName>{labName}</LabName>
            <Flex direction="row" wrap="wrap">
              <SButton
                disabled={props.loading}
                onClick={() => addAllServices(labName)}
                size="small"
                variant="contained"
                width="10rem"
              >
                Agregar todos
              </SButton>
              <SButton
                disabled={props.loading}
                onClick={() => removeAllServices(labName)}
                size="small"
                variant="contained"
                width="10rem"
              >
                Quitar todos
              </SButton>
            </Flex>
          </Flex>
        ))}
      </Flex>
      <hr />
      <FormControl fullWidth>
        <Select
          MenuProps={{ style: { height: 600 } }}
          multiple
          value={props.nurseLabServiceIds}
          onChange={handleChange}
          fullWidth
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Flex wrap="wrap">
              {(selected as string[]).map((value: string) => (
                <Chip
                  variant="outlined"
                  color="primary"
                  key={value}
                  label={servicesHash[value]}
                  clickable
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={(e) => handleDelete(e, value)}
                />
              ))}
            </Flex>
          )}
        >
          {props.labServicesIds.map((id: string, index: number) => (
            <MenuItem key={index} value={id}>
              <Checkbox
                checked={props.nurseLabServiceIds.includes(id)}
                color="primary"
              />
              {servicesHash[id]}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Servicios de {props.formHelperText}</FormHelperText>
        {/* @ts-expect-error Property 'center' does not exist on type 'JSX.IntrinsicElements'.ts(2339) */}
        <center>
          <SButton
            width="20rem"
            variant="outlined"
            color="primary"
            disabled={props.loading}
            onClick={handleSubmit}
          >
            Actualizar Servicios
          </SButton>
          {/* @ts-expect-error Property 'center' does not exist on type 'JSX.IntrinsicElements'.ts(2339) */}
        </center>
      </FormControl>
    </Flex>
  );
};

export default LabServiceSelect;
