import React, { useEffect, useState } from "react";

import Flex from "../../components/Containers/Flex";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import Checkbox from "@material-ui/core/Checkbox";
import { SectionHeader } from "../../components/Containers/SpacedPaper";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Bold from "../../components/Typography/Bold";
import SButton from "../../components/Buttons/SButton";
import { nurseAPI } from "../../utils/api/v2";

const NewServicesSelect = (props: any) => {
  const [originalMedicalServices, setOriginalMedicalServices] = useState<any>(
    []
  );
  const [originalMedicalActions, setOriginalMedicalActions] = useState<any>([]);

  useEffect(() => {
    if (originalMedicalServices.length === 0) {
      setOriginalMedicalServices(props.selectedMedicalServices);
    }
    if (originalMedicalActions.length === 0) {
      setOriginalMedicalActions(props.selectedMedicalActions);
    }
  }, [props.selectedMedicalServices, props.selectedMedicalActions]);

  const handleDelete = (
    event: any,
    value: string,
    type: "medicalservice" | "medicalaction"
  ) => {
    const selectedItems =
      type === "medicalservice"
        ? props.selectedMedicalServices
        : props.selectedMedicalActions;
    const setSelectedItems =
      type === "medicalservice"
        ? props.setSelectedMedicalServices
        : props.setSelectedMedicalActions;
    event.stopPropagation();
    const newSelectedItems = selectedItems.filter(
      (item: any) => item.id !== value
    );
    setSelectedItems(newSelectedItems);
  };

  const handleChange = (
    event: any,
    type: "medicalservice" | "medicalaction"
  ) => {
    const ids = event.target.value;
    const items =
      type === "medicalservice" ? props.medicalServices : props.medicalActions;
    const setSelectedItems =
      type === "medicalservice"
        ? props.setSelectedMedicalServices
        : props.setSelectedMedicalActions;
    const newItems = items.filter((item: any) => ids.includes(item.id));
    setSelectedItems(newItems);
  };

  const handleSubmit = async (type: "medicalservice" | "medicalaction") => {
    props.setLoading(true);
    try {
      const postRequest =
        type === "medicalservice"
          ? nurseAPI.addMedicalServices
          : nurseAPI.addMedicalActions;

      const deleteRequest =
        type === "medicalservice"
          ? nurseAPI.deleteMedicalServices
          : nurseAPI.deleteMedicalActions;

      const originalData =
        type === "medicalservice"
          ? originalMedicalServices
          : originalMedicalActions;

      const selectedData =
        type === "medicalservice"
          ? props.selectedMedicalServices
          : props.selectedMedicalActions;

      const newData = selectedData
        .filter(
          (item: any) =>
            !originalData.some(
              (originalItem: any) => item.id === originalItem.id
            )
        )
        .map((item: any) => item.id);

      const deletedData = originalData
        .filter(
          (item: any) =>
            !selectedData.some(
              (selectedItem: any) => item.id === selectedItem.id
            )
        )
        .map((item: any) => item.id);

      await postRequest(props.state.id, newData);
      await deleteRequest(props.state.id, deletedData);
    } catch (error) {
      props.setError(error);
    }
    props.setLoading(false);
  };

  const medicalServicesHash = props.medicalServices.reduce(
    (acc, medicalService) => {
      acc[medicalService.id] = medicalService.name;
      return acc;
    },
    {}
  );

  const medicalActionsHash = props.medicalActions.reduce(
    (acc, medicalAction) => {
      acc[medicalAction.id] = medicalAction.name;
      return acc;
    },
    {}
  );

  console.log("medicalActions", props.medicalActions);
  console.log("selectedMedicalActions", props.selectedMedicalActions);

  return (
    <Flex direction="column" padding="0 2rem 2rem 2rem">
      <SectionHeader>
        <Bold>{"New Services"}</Bold>
      </SectionHeader>
      <hr />
      <Bold>Servicios Médicos</Bold>
      <FormControl fullWidth>
        <Select
          MenuProps={{ style: { height: 600 } }}
          multiple
          value={props.selectedMedicalServices.map(({ id }) => id)}
          onChange={(e) => handleChange(e, "medicalservice")}
          fullWidth
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Flex wrap="wrap">
              {(selected as string[]).map((value: string) => (
                <Chip
                  variant="outlined"
                  color="primary"
                  key={value}
                  label={medicalServicesHash[value]}
                  clickable
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={(e) => handleDelete(e, value, "medicalservice")}
                />
              ))}
            </Flex>
          )}
        >
          {props.medicalServices.map(({ id }, index: number) => (
            <MenuItem key={index} value={id}>
              <Checkbox
                checked={props?.selectedMedicalServices
                  ?.map(({ id }) => id)
                  .includes(id)}
              />
              {medicalServicesHash[id]}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Servicios Médicos</FormHelperText>
        {/* @ts-expect-error Property 'center' does not exist on type 'JSX.IntrinsicElements'.ts(2339) */}
        <center>
          <SButton
            width="20rem"
            variant="outlined"
            color="primary"
            disabled={props.loading}
            onClick={() => handleSubmit("medicalservice")}
          >
            Actualizar Servicios
          </SButton>
          {/* @ts-expect-error Property 'center' does not exist on type 'JSX.IntrinsicElements'.ts(2339) */}
        </center>
      </FormControl>
      <hr />
      <Bold>Acciones Médicas</Bold>
      <FormControl fullWidth>
        <Select
          MenuProps={{ style: { height: 600 } }}
          multiple
          value={props.selectedMedicalActions.map(({ id }) => id)}
          onChange={(e) => handleChange(e, "medicalaction")}
          fullWidth
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Flex wrap="wrap">
              {(selected as string[]).map((value: string) => (
                <Chip
                  variant="outlined"
                  color="primary"
                  key={value}
                  label={medicalActionsHash[value]}
                  clickable
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={(e) => handleDelete(e, value, "medicalaction")}
                />
              ))}
            </Flex>
          )}
        >
          {props.medicalActions.map(({ id }, index: number) => (
            <MenuItem key={index} value={id}>
              <Checkbox
                checked={props?.selectedMedicalActions
                  ?.map(({ id }) => id)
                  .includes(id)}
              />
              {medicalActionsHash[id]}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Servicios Médicos</FormHelperText>
        {/* @ts-expect-error Property 'center' does not exist on type 'JSX.IntrinsicElements'.ts(2339) */}
        <center>
          <SButton
            width="20rem"
            variant="outlined"
            color="primary"
            disabled={props.loading}
            onClick={() => handleSubmit("medicalaction")}
          >
            Actualizar Servicios
          </SButton>
          {/* @ts-expect-error Property 'center' does not exist on type 'JSX.IntrinsicElements'.ts(2339) */}
        </center>
      </FormControl>
    </Flex>
  );
};

export default NewServicesSelect;
