import { AxiosResponse } from "axios";

import api from "../api";

import type { ItemType } from "../../../components/massiveUpload/ItemUpload";
import type { Item, ListItemsPayload, SectorItemsPayload } from "../../interfaces/Services";
import type {
  MassiveItemData,
  MassiveLabMeasuresData,
  MassiveLabPanelsData,
  MassivePricesUploadData
} from "../../interfaces/massiveUploads";

export async function createMultipleItems(items: MassiveItemData, type: ItemType) {
  try {
    const res = await api.post("v2/items/massive-upload/", {
      items,
      "item_type": type,
    });
    return res;
  } catch (error) {
    return {
      error: JSON.stringify(error.response?.data || error.message),
      status: error.response?.status || 500,
    };
  }
}

export async function createMultipleLabMeasures(labMeasures: MassiveLabMeasuresData) {
  try {
    const res = await api.post("v2/lab-measures/massive-upload/", { lab_measures: labMeasures });
    return res;
  }
  catch (error) {
    return {
      error: JSON.stringify(error.response?.data || error.message),
      status: error.response?.status || 500,
    };
  }
}

export async function createMultipleLabPanels(labPanels: MassiveLabPanelsData) {
  try {
    const res = await api.post("v2/lab-panels/massive-upload/", { lab_panels: labPanels });
    return res;
  } catch (error) {
    return {
      error: JSON.stringify(error.response?.data || error.message),
      status: error.response?.status || 500,
    };
  }
}

export async function listItems(): Promise<Item[] | undefined> {
  try {
    const res = await api.get<ListItemsPayload>("v2/items/");
    return res.data.results;
  } catch (error) {
    return undefined;
  }
}

export async function filterItems(search: string): Promise<AxiosResponse<any> | undefined> {
  try {
    return api.get(`v2/items/?search=${search}`);
  } catch (error) {
    return undefined
  }
};

export async function updateMultiplePrices(labItems: MassivePricesUploadData) {
  try {
    const res = await api.post("v2/items/massive-update-prices/", { lab_items: labItems });
    return res;
  } catch (error) {
    return {
      error: JSON.stringify(error.response?.data || error.message),
      status: error.response?.status || 500,
    };
  }
}

export async function getSectorItems(sectorId: string): Promise<SectorItemsPayload | undefined> {
  try {
    const res = await api.get<SectorItemsPayload>("v2/items/sector-items/", {
      params: { sector_id: sectorId },
    });
    return res.data;
  } catch (error) {
    return undefined;
  }
}
