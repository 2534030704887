import React, { useState, useEffect } from "react";

import { navigate } from "gatsby";

import nurseService from "../../../utils/api/v1/nurseService";
import labService from "../../../utils/api/v1/labService";

// typings
import LabService from "../../../utils/interfaces/LabServices";

// components
import PrivateRoute from "../../../components/Authentication/PrivateRoute";
import LoadingError from "../../../components/Loaders/LoadingError";
import LabServiceSelect from "../../../components/LabServices/LabServiceSelect";
import { SpacedPaper } from "../../../components/Containers/SpacedPaper";
import { NurseName } from "../../../utils/interfaces/Nurse";
import NewServicesSelect from "../../../components/Nurses/NewServicesSelect";
import { medicalActionAPI, nurseAPI } from "../../../utils/api/v2";
import { Locale } from "../../../utils/interfaces/Locale";
import { listItems } from "../../../utils/api/v2/items";

const getLabServicesArray = (labServices: LabService[]) =>
  labServices.map((labService: LabService) => ({
    displayName: `${labService.name} - $${labService.price} - ${labService.lab_name} ${labService.widgets}`,
    labName: labService.lab_name,
    id: labService.id,
  }));

const getServiceIds = (labServices: LabService[]) =>
  labServices
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((labService: LabService) => labService.id);

interface LabServiceItem {
  displayName: string;
  id: string;
  labName: string;
}

interface ExamsProps {
  id: string;
  location: {
    state: {
      id: string;
      names: string;
      last_names: string;
      country: Locale;
    };
  };
}

const Exams = (props: ExamsProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({});

  const [nurseData, setNurseData] = useState<NurseName | undefined>();

  // Covid Lab Services
  const [covidLabServicesIds, setCovidLabServicesIds] = useState<string[]>([]);
  const [nurseCovidLabServicesIds, setNurseCovidLabServicesIds] = useState<
    string[]
  >([]);
  const [defaultNurseCovidLabServicesIds, setDefaultNurseCovidLabServicesIds] =
    useState<string[]>([]);
  const [covidLabServices, setCovidLabServices] = useState<LabServiceItem[]>(
    []
  );

  // Blood and Urine Lab Services
  const [bloodAndUrineLabServicesIds, setBloodAndUrineLabServicesIds] =
    useState<string[]>([]);
  const [
    nurseBloodAndUrineLabServicesIds,
    setNurseBloodAndUrineLabServicesIds,
  ] = useState<string[]>([]);
  const [
    defaultNurseBloodAndUrineLabServicesIds,
    setDefaultNurseBloodAndUrineLabServicesIds,
  ] = useState<string[]>([]);
  const [bloodAndUrineLabServices, setBloodAndUrineLabServices] = useState<
    LabServiceItem[]
  >([]);

  // Kine Lab Services
  const [kineLabServicesIds, setKineLabServicesIds] = useState<string[]>([]);
  const [nurseKineLabServicesIds, setNurseKineLabServicesIds] = useState<
    string[]
  >([]);
  const [defaultNurseKineLabServicesIds, setDefaultNurseKineLabServicesIds] =
    useState<string[]>([]);
  const [kineLabServices, setKineLabServices] = useState<LabServiceItem[]>([]);

  // Vaccine Lab Services
  const [vaccineLabServicesIds, setVaccineLabServicesIds] = useState<string[]>(
    []
  );
  const [nurseVaccineLabServicesIds, setNurseVaccineLabServicesIds] = useState<
    string[]
  >([]);
  const [
    defaultNurseVaccineLabServicesIds,
    setDefaultNurseVaccineLabServicesIds,
  ] = useState<string[]>([]);
  const [vaccineLabServices, setVaccineLabServices] = useState<
    LabServiceItem[]
  >([]);

  // Electrocardiogram (ECG) Lab Services
  const [ecgLabServicesIds, setEcgLabServicesIds] = useState<string[]>([]);
  const [nurseEcgLabServicesIds, setNurseEcgLabServicesIds] = useState<
    string[]
  >([]);
  const [defaultNurseEcgLabServicesIds, setDefaultNurseEcgLabServicesIds] =
    useState<string[]>([]);
  const [ecgLabServices, setEcgLabServices] = useState<LabServiceItem[]>([]);

  const state = props.location.state;
  const [medicalServices, setMedicalServices] = useState<any>([]);
  const [medicalActions, setMedicalActions] = useState<any>([]);
  const [selectedMedicalServices, setSelectedMedicalServices] = useState<any>(
    []
  );
  const [selectedMedicalActions, setSelectedMedicalActions] = useState<any>([]);

  useEffect(() => {
    if (!state) {
      navigate("/");
      return;
    }
    setNurseData(state);

    async function fetchData() {
      setLoading(true);
      try {
        const [activeLabServices, nurseLabServices] = await Promise.all([
          labService.filterByCategory({ allow_zero_price: true }),
          nurseService.fetchServices(state.id),
        ]);
        setCovidLabServicesIds(
          getServiceIds(
            activeLabServices.data.data.filter(
              ({ category }) => category === "covid"
            )
          )
        );
        setCovidLabServices(
          getLabServicesArray(
            activeLabServices.data.data.filter(
              ({ category }) => category === "covid"
            )
          )
        );

        setBloodAndUrineLabServicesIds(
          getServiceIds(
            activeLabServices.data.data.filter(({ category }) =>
              ["blood", "urine"].includes(category)
            )
          )
        );
        setBloodAndUrineLabServices(
          getLabServicesArray(
            activeLabServices.data.data.filter(({ category }) =>
              ["blood", "urine"].includes(category)
            )
          )
        );

        setKineLabServicesIds(
          getServiceIds(
            activeLabServices.data.data.filter(
              ({ category }) => category === "kine"
            )
          )
        );
        setKineLabServices(
          getLabServicesArray(
            activeLabServices.data.data.filter(
              ({ category }) => category === "kine"
            )
          )
        );

        setVaccineLabServicesIds(
          getServiceIds(
            activeLabServices.data.data.filter(
              ({ category }) => category === "vaccine"
            )
          )
        );
        setVaccineLabServices(
          getLabServicesArray(
            activeLabServices.data.data.filter(
              ({ category }) => category === "vaccine"
            )
          )
        );

        setEcgLabServicesIds(
          getServiceIds(
            activeLabServices.data.data.filter(
              ({ category }) => category === "electrocardiogram"
            )
          )
        );
        setEcgLabServices(
          getLabServicesArray(
            activeLabServices.data.data.filter(
              ({ category }) => category === "electrocardiogram"
            )
          )
        );

        setNurseCovidLabServicesIds(
          getServiceIds(
            nurseLabServices.data.data.filter(
              ({ category }) => category === "covid"
            )
          )
        );
        setDefaultNurseCovidLabServicesIds(
          getServiceIds(
            nurseLabServices.data.data.filter(
              ({ category }) => category === "covid"
            )
          )
        );
        setNurseBloodAndUrineLabServicesIds(
          getServiceIds(
            nurseLabServices.data.data.filter(({ category }) =>
              ["blood", "urine"].includes(category)
            )
          )
        );
        setDefaultNurseBloodAndUrineLabServicesIds(
          getServiceIds(
            nurseLabServices.data.data.filter(({ category }) =>
              ["blood", "urine"].includes(category)
            )
          )
        );
        setNurseKineLabServicesIds(
          getServiceIds(
            nurseLabServices.data.data.filter(
              ({ category }) => category === "kine"
            )
          )
        );
        setDefaultNurseKineLabServicesIds(
          getServiceIds(
            nurseLabServices.data.data.filter(
              ({ category }) => category === "kine"
            )
          )
        );
        setNurseVaccineLabServicesIds(
          getServiceIds(
            nurseLabServices.data.data.filter(
              ({ category }) => category === "vaccine"
            )
          )
        );
        setDefaultNurseVaccineLabServicesIds(
          getServiceIds(
            nurseLabServices.data.data.filter(
              ({ category }) => category === "vaccine"
            )
          )
        );

        setNurseEcgLabServicesIds(
          getServiceIds(
            nurseLabServices.data.data.filter(
              ({ category }) => category === "electrocardiogram"
            )
          )
        );
        setDefaultNurseEcgLabServicesIds(
          getServiceIds(
            nurseLabServices.data.data.filter(
              ({ category }) => category === "electrocardiogram"
            )
          )
        );
      } catch (err) {
        console.log(err);
        setError(err);
      }
      setLoading(false);
    }

    const fetchNewServices = async () => {
      setLoading(true);
      try {
        const [
          medicalServices,
          medicalActions,
          selectedMedicalServices,
          selectedMedicalActions,
        ] = await Promise.all([
          listItems(),
          medicalActionAPI.list(state.country),
          nurseAPI.listMedicalServices(state.id),
          nurseAPI.listMedicalActions(state.id),
        ]);
        setMedicalServices(medicalServices);
        setMedicalActions(medicalActions.data);
        setSelectedMedicalServices(selectedMedicalServices.data);
        setSelectedMedicalActions(selectedMedicalActions.data);
      } catch (err) {
        console.log(err);
        setError(err);
      }
      setLoading(false);
    };
    fetchNewServices();
    fetchData();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError loading={loading} error={error} />
      {!loading && nurseData && (
        <>
          <h2>
            Detalle de exámenes {nurseData.names} {nurseData.last_names}
          </h2>
          <SpacedPaper variant="outlined">
            <LabServiceSelect
              nurseId={props.id}
              loading={loading}
              setLoading={setLoading}
              setError={setError}
              labServicesIds={covidLabServicesIds}
              nurseLabServiceIds={nurseCovidLabServicesIds}
              setNurseLabServiceIds={setNurseCovidLabServicesIds}
              defaultNurseLabServiceIds={defaultNurseCovidLabServicesIds}
              setDefaultNurseLabServiceIds={setDefaultNurseCovidLabServicesIds}
              labServices={covidLabServices}
              label="PCR"
              formHelperText="Covid"
            />
          </SpacedPaper>
          <SpacedPaper variant="outlined">
            <LabServiceSelect
              nurseId={props.id}
              loading={loading}
              setLoading={setLoading}
              setError={setError}
              labServicesIds={bloodAndUrineLabServicesIds}
              nurseLabServiceIds={nurseBloodAndUrineLabServicesIds}
              setNurseLabServiceIds={setNurseBloodAndUrineLabServicesIds}
              defaultNurseLabServiceIds={
                defaultNurseBloodAndUrineLabServicesIds
              }
              setDefaultNurseLabServiceIds={
                setDefaultNurseBloodAndUrineLabServicesIds
              }
              labServices={bloodAndUrineLabServices}
              label="Sangre/Orina"
              formHelperText="Sangre/Orina"
            />
          </SpacedPaper>
          <SpacedPaper variant="outlined">
            <LabServiceSelect
              nurseId={props.id}
              loading={loading}
              setLoading={setLoading}
              setError={setError}
              labServicesIds={kineLabServicesIds}
              nurseLabServiceIds={nurseKineLabServicesIds}
              setNurseLabServiceIds={setNurseKineLabServicesIds}
              defaultNurseLabServiceIds={defaultNurseKineLabServicesIds}
              setDefaultNurseLabServiceIds={setDefaultNurseKineLabServicesIds}
              labServices={kineLabServices}
              label="Kinesiología"
              formHelperText="Kinesiología"
            />
          </SpacedPaper>
          <SpacedPaper variant="outlined">
            <LabServiceSelect
              nurseId={props.id}
              loading={loading}
              setLoading={setLoading}
              setError={setError}
              labServicesIds={vaccineLabServicesIds}
              nurseLabServiceIds={nurseVaccineLabServicesIds}
              setNurseLabServiceIds={setNurseVaccineLabServicesIds}
              defaultNurseLabServiceIds={defaultNurseVaccineLabServicesIds}
              setDefaultNurseLabServiceIds={
                setDefaultNurseVaccineLabServicesIds
              }
              labServices={vaccineLabServices}
              label="Vacunación"
              formHelperText="Vacunación"
            />
          </SpacedPaper>
          <SpacedPaper variant="outlined">
            <LabServiceSelect
              nurseId={props.id}
              loading={loading}
              setLoading={setLoading}
              setError={setError}
              labServicesIds={ecgLabServicesIds}
              nurseLabServiceIds={nurseEcgLabServicesIds}
              setNurseLabServiceIds={setNurseEcgLabServicesIds}
              defaultNurseLabServiceIds={defaultNurseEcgLabServicesIds}
              setDefaultNurseLabServiceIds={setDefaultNurseEcgLabServicesIds}
              labServices={ecgLabServices}
              label="ECG"
              formHelperText="ECG"
            />
          </SpacedPaper>
          <SpacedPaper variant="outlined">
            <NewServicesSelect
              state={state}
              setLoading={setLoading}
              loading={loading}
              setError={setError}
              medicalServices={medicalServices}
              setMedicalServices={setMedicalServices}
              medicalActions={medicalActions}
              setMedicalActions={setMedicalActions}
              selectedMedicalServices={selectedMedicalServices}
              setSelectedMedicalServices={setSelectedMedicalServices}
              selectedMedicalActions={selectedMedicalActions}
              setSelectedMedicalActions={setSelectedMedicalActions}
            />
          </SpacedPaper>
        </>
      )}
    </PrivateRoute>
  );
};

export default Exams;
